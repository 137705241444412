import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
	description,
	lang,
	meta,
	keywords,
	title,
	image,
}) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						siteUrl
					}
				}
			}
		`
	);

	const metaUrl = site.siteMetadata.siteUrl;
	const defaultTitle = `${ site.siteMetadata.title }`;
	const metaTitle = title || site.siteMetadata.title;
	const metaDescription = description ? description.replace(/(<([^>]+)>)/ig, '') : site.siteMetadata.description;
	const metaImage = image || `${ site.siteMetadata.siteUrl }/assets/images/violux-meta-image.jpg`;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			defaultTitle={defaultTitle}
			titleTemplate={`%s | ${ site.siteMetadata.title }`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:url`,
					content: metaUrl,
				},
				{
					property: `og:title`,
					content: metaTitle,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:image`,
					content: metaImage,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `twitter:card`,
					content: `summary`,
				},
				{
					property: `twitter:title`,
					content: metaTitle,
				},
				{
					property: `twitter:description`,
					content: metaDescription,
				},
				{
					property: `twitter:image`,
					content: metaImage,
				},
			]
				.concat(
					keywords.length > 0
						? {
							name: `keywords`,
							content: keywords.join(`, `),
						}
						: []
				)
				.concat(meta)}
		/>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	keywords: [],
};

export default SEO;
